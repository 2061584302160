<template>
  <aside class="sidebar overlay-container">
    <button class="sidebar-toggle" @click="toggleSidebar">
      <span class="material-symbols-rounded icon">double_arrow</span>
      <span>Close Sidebar</span>
    </button>

    <header class="sidebar-header">
      <button
        class="new-chat-button"
        :disabled="$route.name === 'ExpertMode'"
        @click="newChat"
      >
        <span class="material-symbols-rounded icon">add</span>
        <span class="fw-normal">New Chat</span>
      </button>
      <form class="search-form">
        <span class="material-symbols-rounded search-icon">search</span>
        <label for="search" class="visually-hidden">Search</label>
        <input
          v-model="searchQuery"
          type="text"
          id="search"
          placeholder="Search"
          aria-label="Search"
          class="search-input"
        />
      </form>
    </header>
    <hr v-if="uploadFiles.length > 0" class="separator mt-3" />
    <section class="document-folders" v-if="uploadFiles.length > 0">
      <header class="folder-header">
        <span class="folder-title mt-2">Uploaded Files</span>
        <p class="folder-description">Files uploaded to this chat/use case.</p>
      </header>
      <ul class="document-list">
        <li
          v-for="(document, index) in uploadFiles"
          :key="index"
          class="document-item"
        >
          <div class="document-icon">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/11adb58bd8294a6318acbe19545f035e5d63440135703ee2b3aabd6a15de2735?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
              alt="Icon"
              class="document-icon-image"
            />
          </div>
          <div class="document-details">
            <span class="document-name">{{ document.name }}</span>
            <span class="document-size">{{ document.size }}</span>
            <!-- <b-button @click="downloadFile(document.file)" variant="primary">Download</b-button> -->
          </div>
          <div @click="downloadFile(document.file)" style="cursor: pointer;">
            <b-icon class="me-1" icon="download"></b-icon>
          </div>
        </li>
      </ul>
    </section>

    <section class="item-list">
      <hr class="separator mt-3" />
      <h4 class="recent-logs-title mt-2">Recent Logs</h4>
      <div v-if="loading" class="overflow-recent">
        <article class="conversation-card pe-none" v-for="n in 10" :key="n">
          <div class="skeleton skeleton-primary"></div>
          <div class="mt-2 conversation-details">
            <div class="skeleton skeleton-secondary"></div>
            <div class="skeleton skeleton-secondary"></div>
          </div>
        </article>
      </div>
      <main v-else-if="!loading && !campaigns.length" class="sidebar-content">
        <img
          loading="lazy"
          width="80"
          height="80"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/15b561ec013b5b3a301dfbcbedc2a1a4cfd9b83e7b2e7c21e08c26e8f7562599?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
          alt="Chat illustration"
          class="chat-illustration"
        />
        <p class="mt-4">
          Chat history will show up here once you start a conversation.
        </p>
      </main>
      <div
        v-else
        :class="{
          'overflow-recent': uploadFiles.length === 0,
          'overflow-recent-files': uploadFiles.length > 0,
        }"
      >
        <div v-if="campaignsCreatedToday.length > 0" class="mb-3">
          <h5 class="conversation-title mt-3">Today</h5>
          <article
            class="conversation-card cursor-pointer"
            @click="NexaResult(campaign)"
            v-for="campaign in campaignsCreatedToday"
            :key="campaign.id"
          >
            <h5 class="conversation-title">{{ campaign.title }}</h5>
            <div class="conversation-details">
              <span class="file-upload-info"
                >{{ campaign.fileCount }} files uploaded</span
              >
              <time :datetime="formatDateTime(campaign.createdAt)">{{
                getTimeDifference(campaign.createdAt)
              }}</time>
            </div>
          </article>
        </div>

        <div v-if="campaignsCreatedYesterday.length > 0" class="mt-4">
          <h5 class="conversation-title">Yesterday</h5>
          <article
            class="conversation-card cursor-pointer"
            @click="NexaResult(campaign)"
            v-for="campaign in campaignsCreatedYesterday"
            :key="campaign.id"
          >
            <h5 class="conversation-title">{{ campaign.title }}</h5>
            <div class="conversation-details">
              <span class="file-upload-info"
                >{{ campaign.fileCount }} files uploaded</span
              >
            </div>
          </article>
        </div>

        <div v-if="campaignsCreatedLast7Days.length > 0" class="mt-4">
          <h5 class="conversation-title">Previous 7 Days</h5>
          <article
            class="conversation-card cursor-pointer"
            @click="NexaResult(campaign)"
            v-for="campaign in campaignsCreatedLast7Days"
            :key="campaign.id"
          >
            <h5 class="conversation-title">{{ campaign.title }}</h5>
            <div class="conversation-details">
              <span class="file-upload-info"
                >{{ campaign.fileCount }} files uploaded</span
              >

              <time :datetime="formatDateTime(campaign.createdAt)">{{
                getTimeDifferenceDay(campaign.createdAt)
              }}</time>
            </div>
          </article>
        </div>

        <div v-if="campaignsCreatedMoreThan7DaysAgo.length > 0" class="mt-4">
          <h5 class="conversation-title">More Than 7 Days</h5>

          <article
            class="conversation-card cursor-pointer"
            @click="NexaResult(campaign)"
            v-for="campaign in campaignsCreatedMoreThan7DaysAgo"
            :key="campaign.id"
          >
            <h5 class="conversation-title">{{ campaign.title }}</h5>
            <div class="conversation-details">
              <span class="file-upload-info"
                >{{ campaign.fileCount }} files uploaded</span
              >
              <time :datetime="formatDateTime(campaign.createdAt)">{{
                getTimeDifferenceDay(campaign.createdAt)
              }}</time>
            </div>
          </article>
        </div>
      </div>
      <!-- <div v-else class="overflow-recent">
        <div>
          <h5 class="conversation-title">Today</h5>
          <article
          class="conversation-card cursor-pointer"
          @click="NexaResult(campaign)"
          v-for="campaign in filteredCampaigns"
          :key="campaign.id"
        >
          <h5 class="conversation-title">{{ campaign.title }}</h5>
          <div class="conversation-details">
            <span class="file-upload-info"
              >{{ campaign.fileCount }} files uploaded</span
            >
            <time :datetime="formatDateTime(campaign.createdAt)">{{
              getTimeDifference(campaign.createdAt)
            }}</time>
          </div>
        </article>
        </div>
      </div> -->
    </section>
  </aside>
</template>

<script>
import nexa from "@/store/nexa";

import {
  startOfToday,
  startOfYesterday,
  subDays,
  isAfter,
  isBefore,
} from "date-fns";
export default {
  data() {
    return {
      loading: true,
      campaigns: [],
      searchQuery: "",
      totalPage: 0,
      currentPage: 1,
      isFetching: false,
    };
  },
  props: {
    uploadFiles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredCampaigns() {
      if (this.searchQuery.length !== 0) {
        const filtered = this.campaigns.filter((campaign) =>
          campaign.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        return filtered;
      } else {
        // console.log(this.campaigns);
        return this.campaigns;
      }
    },
    campaignsCreatedToday() {
      const startOfTodayDate = startOfToday();
      const endOfTodayDate = new Date(startOfTodayDate);
      endOfTodayDate.setDate(endOfTodayDate.getDate() + 1); // Move to the next day

      return this.filteredCampaigns.filter((campaign) => {
        const campaignDate = new Date(campaign.createdAt);
        return (
          isAfter(campaignDate, startOfTodayDate) &&
          isBefore(campaignDate, endOfTodayDate)
        );
      });
    },

    campaignsCreatedYesterday() {
      const startOfYesterdayDate = startOfYesterday();
      const startOfTodayDate = startOfToday();

      return this.filteredCampaigns.filter((campaign) => {
        const campaignDate = new Date(campaign.createdAt);
        return (
          isAfter(campaignDate, startOfYesterdayDate) &&
          isBefore(campaignDate, startOfTodayDate)
        );
      });
    },

    campaignsCreatedLast7Days() {
      const sevenDaysAgoDate = subDays(new Date(), 7);
      const startOfTodayDate = startOfToday();

      return this.filteredCampaigns.filter((campaign) => {
        const campaignDate = new Date(campaign.createdAt);
        return (
          isAfter(campaignDate, sevenDaysAgoDate) &&
          isBefore(campaignDate, startOfTodayDate)
        );
      });
    },

    campaignsCreatedMoreThan7DaysAgo() {
      const sevenDaysAgoDate = subDays(new Date(), 7);

      return this.filteredCampaigns.filter((campaign) => {
        const campaignDate = new Date(campaign.createdAt);
        return isBefore(campaignDate, sevenDaysAgoDate);
      });
    },
  },
  methods: {
    async fetchData(page) {
      let response;

      try {
        // Determine which data to fetch based on the route
        if (this.$route.name === "ExpertMode") {
          response = await nexa.AllResult(page);
        } else {
          const type = this.getCampaignType();
          response = await nexa.UseCaseResult(type, page);
        }

        if (response?.data) {
          // If the data is received, update loading state and campaigns
          this.loading = false;
          const DATA = response.data.data.data;
          this.totalPage = response.data.data.last_page; // Update the total number of pages if available

          // Push new campaigns to existing list
          this.campaigns.push(
            ...DATA.map((item) => ({
              id: item.id,
              title: item.title ?? item.type,
              type: item.type,
              fileCount: item.file_uploaded_count ?? 0,
              createdAt: item.created_at,
            }))
          );
        }
      } catch (error) {
        // Handle errors in fetching data
        this.$toast.error(
          "Failed to fetch data: " + (error.message || "Unknown error")
        );
        this.loading = false; // Ensure loading state is set to false on error
      }
    },
    downloadFile(file) {
      const url = file;
      console.log(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'desired_filename.pdf'; // Set the desired filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },
    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);

      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    },
    getTimeDifferenceDay(date) {
      const now = new Date();
      const createdAt = new Date(date);

      now.setUTCHours(0, 0, 0, 0);
      createdAt.setUTCHours(0, 0, 0, 0);

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = now - createdAt;

      // Convert the difference to days
      const daysElapsed = Math.floor(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
      );

      return `${daysElapsed} days`;
    },
    newChat() {
      this.$router.push({ name: "ExpertMode" });
    },
    getCampaignType() {
      const typeMapping = {
        Chat: "chatbot",
        DocumentInsight: "nexa_document_insights",
        DeepResearch: "nexa_deep_research",
        CompareJuri: "nexa_compare_jurisdictions",
        FindPrecedent: "nexa_find_precedents",
        BuildArgument: "nexa_build_argument",
        LegalMemo: "nexa_build_memo",
        BuildMemo: "nexa_build_memo",
        AnalyzePleading: "nexa_analyze_pleadings",
        ContractDiligence: "nexa_due_diligence",
        DraftContract: "nexa_build_contract",
        SummarizeCase: "nexa_summarizer",
      };
      for (const key in typeMapping) {
        if (this.$route.name.includes(key)) {
          return typeMapping[key];
        }
      }
      return null;
    },
    NexaResult(campaign) {
      let routeName = "";

      switch (campaign.type) {
        case "chatbot":
          routeName = "ChatNeXa";
          break;
        case "nexa_deep_research":
          routeName = "DeepResearchResult";
          break;
        case "nexa_compare_jurisdictions":
          routeName = "CompareJuriResult";
          break;
        case "nexa_find_precedents":
          routeName = "FindPrecedentResult";
          break;
        case "nexa_analyze_pleadings":
          routeName = "AnalyzePleadingsResult";
          break;
        case "nexa_document_insights":
          routeName = "DocumentInsightResult";
          break;
        case "nexa_build_argument":
          routeName = "BuildArgumentResult";
          break;
        case "nexa_build_memo":
          routeName = "BuildMemoResult";
          break;
        case "nexa_due_diligence":
          routeName = "ContractDiligenceResult";
          break;
        case "nexa_build_contract":
          routeName = "DraftContractResult";
          break;
        case "nexa_summarizer":
          routeName = "SummarizeCaseResult";
          break;
        default:
          break;
      }

      this.$router.push({
        name: routeName,
        params: { id: campaign.id },
      });
    },
  },
  async created() {
    await this.fetchData(1); // Load data for the first page
  },

  mounted() {
    const scrollArea = document.querySelector(".overflow-recent");
    if (scrollArea) {
      const handleScroll = async () => {
        if (
          scrollArea.scrollTop + scrollArea.clientHeight >=
          scrollArea.scrollHeight - 10
        ) {
          if (!this.isFetching && this.currentPage < this.totalPage) {
            this.isFetching = true; // Set fetching state to true
            this.currentPage++; // Increment the current page
            await this.fetchData(this.currentPage); // Fetch data for the next page
            this.isFetching = false; // Reset fetching state after completion
          }
        }
      };

      scrollArea.addEventListener("scroll", handleScroll);
      // console.log(this.campaigns);
      // Cleanup the event listener when the component is unmounted
      this.$once("hook:beforeDestroy", () => {
        scrollArea.removeEventListener("scroll", handleScroll);
      });
    }
  },
};
</script>

<style scoped>
.document-folders {
  display: flex;
  width: 100%;
  max-height: 13.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex-shrink: 0;
}

.folder-title {
  color: var(--Neutral-Dark-Grey, #86888d);

  /* Subtext */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.folder-description {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 12px/24px Poppins, sans-serif;
  margin: 4px 0 0;
}

.document-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 0.5rem;
  width: 100%;
}

.document-item {
  display: flex;
  align-items: center;
}

.document-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.document-icon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.document-details {
  display: flex;
  justify-content: space-between;
  flex: 1;
  color: var(--Neutral-Dark-Grey, #86888d);
  font: 400 12px/24px Poppins, sans-serif;
  align-items: center;
}

.document-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: 11rem;
  text-overflow: ellipsis;
}

.document-size {
  margin-left: 15px;
}

.sidebar {
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 300px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 12px;
  top: 0 !important;
  margin-top: 65px;
}

.sidebar-toggle,
.new-chat-button,
.show-all-button {
  border-radius: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 4px 16px;
  background: none;
  border: none;
}

.show-all-button {
  justify-content: center !important;
  color: var(--Primary-Blue, #0e4485) !important;
}

.show-all-button .icon-small {
  color: var(--Primary-Blue, #0e4485) !important;
}

button.sidebar-toggle {
  color: var(--Primary-Blue, #0e4485);
}

.icon,
.search-icon {
  width: 24px;
  height: 24px;
}

.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}

.icon-filter {
  margin: auto;
  font-size: 18px;
}

.sidebar-header {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-chat-button {
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
  padding: 6px 16px;
}

.new-chat-button:disabled {
  color: var(--mid-grey);
  background-color: #0e44851a;
  pointer-events: none;
}

.search-form {
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 6px 12px 6px 16px;
  gap: 3px;
  width: 100%;
}

.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.search-icon {
  width: 24px;
  height: 24px;
}

.item-list {
  /* margin-top: 24px; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid var(--mid-grey);
  width: 100%;
}

.recent-logs-title {
  margin: 0px;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.conversation-card,
.alt-card {
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.conversation-card:hover {
  background: #f2f3f3;
}

.alt-card {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}

.conversation-title {
  font-size: 14px !important;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 400 !important;
  margin-bottom: 5px;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.file-upload-info {
  flex: 1;
}

.sidebar-image {
  position: absolute;
  right: 77px;
  bottom: 97px;
  width: 20px;
  height: 21px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.upload-button {
  span {
    color: #86888d;
  }
}

input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #d1d2d5;
}

.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem;
  /* Adjust the size */
  font-weight: 600;
  /* Semi-bold for emphasis */
  color: #333;
  /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888;
  /* Light gray for the subtext */
  font-size: 0.875rem;
  /* Smaller font size for description */
  margin: 0;
  margin-top: 4px;
  /* Adds slight spacing below the heading */
}

/* Styling the filter and search area */
.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  color: #333;
  /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #f0f0f0;
}

.search-input::placeholder {
  color: #888888;
}

.icon-search {
  color: #888888;
}

.country-option {
  left: 18%;
  width: 100px !important;
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.countryOption {
  cursor: pointer;
  margin-bottom: 10px;

  span {
    color: #86888d;
    font-size: 14px;
  }
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}

.modal-icon {
  width: 24px;
  height: 24px;
  color: #383a3e;
  cursor: pointer;
}

.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}

.swift {
  border-radius: 4px;
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.toggle {
  display: flex;
  width: 40px;
}

.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}

.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.overflow-recent {
  overflow: scroll;
  max-height: 600px;
}

.overflow-recent-files {
  max-height: 400px;
  overflow-y: auto;
}

.skeleton {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
}

.skeleton-primary {
  width: 958px;
  height: 16px;
  max-width: 100%;
}

.skeleton-secondary {
  width: 684px;
  height: 16px;
  max-width: 100%;
}

.skeleton-tertiary {
  width: 1020px;
  height: 16px;
  max-width: 100%;
}

.skeleton-quaternary {
  width: 100%;
  height: 16px;
}

.sidebar-content {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
